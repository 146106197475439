import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import GiftsDashboard from './components/gifts-dashboard.component';
import OffersList from '../offer/components/offers-list.component';
import Page from '../shared/components/page.component';
import ReferralDashboard from './components/referral-dashboard.component';
import { menuItem, setPage } from '../store/actions/app.action';
import { fetchGet as fetchGetOffers, setDetails } from '../store/actions/offer.action';
import { setDetails as setReferralDetails } from '../store/actions/referral.action';
import UserSummary from './components/user-summary.component';
import Config from '../config';

class ConnectedDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
    }

    componentDidMount() {
        this.props.setPage(menuItem.DASHBOARD);
        this.props.fetchGetOffers();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.DETAILS:
                this.props.setPage(menuItem.OFFERS);
                this.props.setDetails(data);
                history.push(`/offers/consult`);
                break;
            case Config.action.OFFERS:
                this.props.setPage(menuItem.OFFERS);
                history.push(`/offers`);
                break;
            case Config.action.REFERRAL:
                this.props.setPage(menuItem.REFERRALS);
                this.props.setReferralDetails(data);
                history.push(`/referrals/new`);
                break;
            case Config.action.REFERRALS:
                this.props.setPage(menuItem.REFERRALS);
                history.push(`/referrals`);
                break;
            default:
        }
    }

    render() {
        const {
            store: {
                app,
                company,
                offer: { loading, offers },
                user,
            },
        } = this.props;

        return (
            <Page className='o-dashboard' loading={loading}>
                <div className='o-dashboard-summary'>
                    <UserSummary
                        app={app}
                        company={company}
                        user={user.account}
                        onClick={() => this.handleAction(Config.action.OFFERS)}
                    />
                </div>
                <div className='o-dashboard-content'>
                    <OffersList
                        company={company}
                        limit={Config.dashboard.offer.MAX}
                        locale={user.account.locale}
                        offers={offers}
                        onDetails={this.handleAction}
                        onReferral={this.handleAction}
                        user={user}
                    />
                </div>
                <div className='o-dashboard-side'>
                    <ReferralDashboard
                        onClick={() => this.handleAction(Config.action.REFERRALS)}
                        user={user.account}
                    />
                    <GiftsDashboard company={company} user={user.account} />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, offer, user }) => {
    return { store: { app, company, offer, user } };
};

const mapDispatchToProps = {
    fetchGetOffers,
    setDetails,
    setPage,
    setReferralDetails,
};

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDashboardPage);

DashboardPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DashboardPage);
