import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import encrypt from './transform';

import rootReducer from './store/reducers';

const configureStore = () => {
    const middlewares = [thunk];
    const enhancers = [];

    const persistConfig = {
        key: 'store',
        storage: storage,
        // whitelist: ['user'] // which reducer want to store
        transforms: [encrypt],
    };
    const pReducer = persistReducer(persistConfig, rootReducer);

    const isDevelopment = process.env.NODE_ENV !== 'production';
    if (isDevelopment) {
        // In development, use redux-logger
        middlewares.push(logger);

        // In development, use the browser's Redux dev tools extension if installed
        if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
            enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
        }
    }

    const store = createStore(pReducer, compose(applyMiddleware(...middlewares), ...enhancers));

    const persistor = persistStore(store);

    return { persistor, store };
};

export default configureStore;
