import { initialState, type } from '../actions/user.action';

const user = (state = initialState, action) => {
    switch (action.type) {
        case type.USER_DECREASE_POINTS:
            return {
                ...state,
                account: {
                    ...state.account,
                    points: {
                        ...state.account.points,
                        available: state.account.points.available - action.value,
                    },
                },
            };
        case type.USER_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.USER_FETCH_ERROR:
            return { ...state, loading: false, error: action.error };
        case type.USER_FETCH_SUCCESS:
            return { ...state, loading: false };
        case type.USER_FETCH_GET_GDPR_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                gdprDialog: true,
                gdprText: action.gdpr,
            };
        case type.USER_FETCH_GET_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                account: action.user,
            };
        case type.USER_FETCH_PUT_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                account: action.user,
            };
        case type.USER_INIT_GDPR:
            return {
                ...state,
                gdprDialog: false,
            };
        case type.USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                account: action.user,
            };
        case type.USER_LOGOUT:
            if (state.refreshId) {
                clearInterval(state.refreshId);
            }
            return { ...initialState };

        case type.USER_SET_AUTH_CONFIG:
            return { ...state, authConfig: action.authConfig };

        case type.USER_SET_CB:
            return { ...state, cb: action.cb };

        case type.USER_SET_MENU:
            return { ...state, menu: action.menu };

        case type.USER_SET_GDPR:
            return { ...state, account: { ...state.account, gdpr: true } };

        case type.USER_SET_LAST_REFRESH:
            if (state.refreshId) {
                clearInterval(state.refreshId);
            }
            return { ...state, lastRefresh: action.lastRefresh, refreshId: action.refreshId };
        default:
            return state;
    }
};

export default user;
