import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { showError } from '../store/actions/message.action';
import { fetchGet } from '../store/actions/user.action';
import { historyHelper as history } from '../core/helpers/history.helper';

class ConnectedAuthPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
        };

        let href = window.location.href;
        const url = new URL(href);
        const searchParams = new URLSearchParams(url.search);

        const client = searchParams.get('client');

        if (client) {
            window.location.replace(
                `${process.env.REACT_APP_AUTH_API}?client=${client}&cb=${window.location.origin}/auth`
            );
        }

        const login = searchParams.get('login');
        const token = searchParams.get('token');

        if (login && token) {
            localStorage.setItem('token', token);
            localStorage.setItem('username', login);
            this.props.fetchGet(() => {
                history.push('/');
            });
        }
    }

    componentDidMount() {
        let href = window.location.href;
        const url = new URL(href);
        const searchParams = new URLSearchParams(url.search);

        const error = searchParams.get('error');
        if (error) {
            this.setState({ error: true });
            let summary = 'app.error.bad.request';
            const message = searchParams.get('message');
            const detail = this.props.intl.formatMessage({ id: message });

            switch (error) {
                case '403':
                    summary = 'app.error.forbidden';
                    break;
                case '404':
                    summary = 'app.error.file.not.found';
                    break;
                case '500':
                    summary = 'app.error.internal.server.error';
                    break;
                default:
            }

            this.props.showError({ summary }, detail);
            history.push('/');
        }
    }

    render() {
        const { error } = this.state;

        return (
            <>
                <div className='o-page-loader'>
                    {!error && <i className='pi pi-spin pi-spinner'></i>}
                    {error && (
                        <Link to='/' onClick={this.handleForgot}>
                            {this.props.intl.formatMessage({ id: 'app.back.to.login' })}
                        </Link>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { store: { user } };
};

const mapDispatchToProps = {
    fetchGet,
    showError,
};

const AuthPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedAuthPage);

AuthPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(AuthPage);
