import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import SubmitButton from '../../shared/components/form/submit-button.component';

let ShopItem = ({ disabled, intl: { formatMessage }, item, loading, onClick, pictureUrl = '' }) => {
    const media = pictureUrl || process.env.REACT_APP_MEDIA;

    let picture = `${media}default-pic.png`;
    if (item.picture) {
        picture = `${media}${item.picture}`;
    }

    const stylePicture = {
        backgroundImage: `url('${picture}')`,
    };

    // const value = `${item.value} ${formatMessage({ id: 'app.points.short' })}`;

    return (
        <div className='o-shop-item' key={item.id}>
            <div className='o-shop-item-title'>{item.title}</div>
            <div className='o-shop-item-description'>{item.description}</div>
            <div className='o-shop-item-info'>
                <div className='o-shop-item-picture' style={stylePicture} />
                <div className='o-shop-item-points'>
                    <div className='o-shop-item-points-content'>
                        <div className='o-shop-item-points-label'>
                            {formatMessage({ id: 'app.points.required' })}
                        </div>
                        <div className='o-shop-item-points-value'>{item.value}</div>
                    </div>
                </div>
            </div>
            <div className='o-shop-item-submit'>
                <SubmitButton
                    disabled={disabled || loading}
                    label={
                        disabled
                            ? formatMessage({ id: 'app.item.not.available' })
                            : formatMessage({ id: 'app.command' })
                    }
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

ShopItem.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ShopItem);
