import React from 'react';

const LoginEmail = ({ label, name, onChange, onKeyPress, placeholder, value }) => {
    return (
        <div className='o-login-password'>
            {label && <label>{label}</label>}
            <input
                id={name}
                name={name}
                type='email'
                value={value}
                placeholder={placeholder ? placeholder : ''}
                onChange={e => onChange({ target: { name: e.target.id, value: e.target.value } })}
                onKeyPress={onKeyPress}
            />
        </div>
    );
};

export default LoginEmail;
