import { postService as post } from '../../shared/services/post.service';
import { fetchGet, logout } from './user.action';

export const type = {
    NOTIFICATION_FETCH_ERROR: 'NOTIFICATION_FETCH_ERROR',
    NOTIFICATION_FETCH_PENDING: 'NOTIFICATION_FETCH_PENDING',
    NOTIFICATION_FETCH_POST_READ_SUCCESS: 'NOTIFICATION_FETCH_POST_READ_SUCCESS',
    NOTIFICATION_SET_LIST: 'NOTIFICATION_SET_LIST',
    NOTIFICATION_SET_NOTIFICATIONS: 'NOTIFICATION_SET_NOTIFICATIONS',
};

export const initialState = {
    loading: false,
    notifications: [],
    list: false,
};

export const fetchError = error => {
    return { type: type.NOTIFICATION_FETCH_ERROR, error };
};

export const fetchPending = () => {
    return { type: type.NOTIFICATION_FETCH_PENDING };
};

export const fetchPostReadSuccess = data => {
    return { type: type.NOTIFICATION_FETCH_POST_READ_SUCCESS };
};

export const fetchPostRead = id => {
    return dispatch => {
        dispatch(fetchPending());

        post.notifRead(id).then(
            response => {
                dispatch(fetchPostReadSuccess(response.data));
                dispatch(fetchGet());
                return response.ok;
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const setList = list => {
    return { type: type.NOTIFICATION_SET_LIST, list };
};

export const setNotifications = notifications => {
    return { type: type.NOTIFICATION_SET_NOTIFICATIONS, notifications };
};
