import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { ProgressBar } from 'primereact/progressbar';
import SubmitButton from '../../shared/components/form/submit-button.component';

const UserSummary = ({ company, intl: { formatMessage }, onClick, user }) => {
    const {
        level: { max, value },
        xp,
    } = user;

    let progressbarTitle = 'app.progressbar.title';
    switch (process.env.REACT_APP_STYLE_DEFAULT) {
        case 'talentsoft':
            progressbarTitle = 'app.progressbar.title.talentsoft';
            break;
        default:
    }

    let progressbarDescription = 'app.progressbar.description';
    if (company.config.doublemoney === 0) {
        progressbarDescription = 'app.progressbar.description.no.primes';
    }

    const progressValue = Math.round((xp / max) * 100);
    return (
        <div className='o-user-summary'>
            <div className='o-user-summary-info'>
                <div className='o-user-summary-picture'></div>
            </div>
            <div className='o-user-summary-progressbar'>
                <div className='o-user-summary-progressbar-title'>
                    {formatMessage({ id: progressbarTitle })}
                </div>
                <div className='o-user-summary-progressbar-description'>
                    {formatMessage({ id: progressbarDescription })}
                </div>
                <ProgressBar showValue={false} value={progressValue} />
                <div className='o-user-summary-progressbar-value'>
                    <div className='o-user-summary-progressbar-level'>
                        {formatMessage({ id: 'app.level' })} {`${value}/5`}
                    </div>
                    <div>
                        {value < 5 && `${xp}/${max} EXP`}
                        {value >= 5 && `${xp} EXP`}
                    </div>
                </div>
            </div>
            <div className='o-user-summary-submit'>
                {onClick && (
                    <SubmitButton label={formatMessage({ id: 'app.referral' })} onClick={onClick} />
                )}
                &nbsp;
            </div>
        </div>
    );
};

UserSummary.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserSummary);
