import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Gifts from '../../shared/components/gifts.component';
import moment from 'moment';
require('moment/min/locales.min');

let OfferSummary = ({ locale = 'fr', offer, onClick, company }) => {
    moment.locale(locale);

    return (
        <div className='o-offer-summary'>
            <div className='o-offer-summary-item' key={offer.id}>
                <div className='o-offer-summary-back' onClick={onClick}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div className='o-offer-summary-logo'></div>
                <div className='o-offer-summary-header'>
                    <div className='o-offer-summary-title'>{offer.title}</div>
                    <div className='o-offer-summary-location'>
                        {`${offer.location} - ${moment(offer.createdAt.toString()).fromNow()}`}
                    </div>
                    <div className='o-offer-summary-title-desc'>{offer.titleDescription}</div>
                </div>
                <div className='o-offer-summary-gifts'>
                    <Gifts
                        currency={offer.currency}
                        points={offer.points}
                        primes={offer.primes}
                        company={company}
                    />
                </div>
            </div>
        </div>
    );
};

OfferSummary.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OfferSummary);
