import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { menuItem } from '../../store/actions/app.action';
import SubmitButton from './form/submit-button.component';
import Config from '../../config';
import moment from 'moment';
require('moment/min/locales.min');

const NewNotification = ({ app, intl: { formatMessage }, onClick, notifications = [] }) => {
    moment.locale(app.locale);
    const notReaded = notifications.filter(item => !item.hasRead);
    const notReadNumber = ('0' + notReaded.length.toString()).slice(-2);

    const notReadedlist = notReaded.slice().splice(0, Config.notification.list.MAX);
    const dynamicNotificationsList = notReadedlist.map(notification => {
        var date = new Date(notification.time * 1000);

        let icon = '';
        let click = null;
        switch (notification.type) {
            case Config.notification.type.COOPT:
                icon = 'notification-coopt-new.png';
                click = () => onClick(menuItem.REFERRALS, notification.id);
                break;
            case Config.notification.type.OFFER:
                icon = 'notification-offer-new.png';
                click = () => onClick(menuItem.OFFERS, notification.id);
                break;
            case Config.notification.type.SHOP:
                icon = 'notification-shop-new.png';
                click = () => onClick(menuItem.SHOP, notification.id);
                break;
            default:
                icon = 'notification-general-new.png';
        }

        const style = {
            backgroundImage: `url('/templates/${app.template}/media/${icon}')`,
        };

        return (
            <div
                className='o-new-notification-item'
                key={notification.id}
                onClick={click ? click : null}
            >
                <div className='o-new-notification-icon' style={style}></div>
                <div className='o-new-notification-text'>{notification.text}</div>
                <div className='o-new-notification-time'>{moment(date).fromNow()}</div>
            </div>
        );
    });

    return (
        <div className='o-new-notification'>
            <div className='o-new-notification-title'>
                {formatMessage({
                    id: 'app.notifications',
                })}
            </div>
            <div className='o-new-notification-description'>{`${notReadNumber} ${formatMessage({
                id: 'app.new.notifications',
            })}`}</div>
            <div className='o-new-notification-items'>{dynamicNotificationsList}</div>
            <div className='o-new-notification-button'>
                <SubmitButton
                    label={formatMessage({ id: 'app.everything' })}
                    onClick={() => onClick(menuItem.NOTIFICATIONS)}
                />
            </div>
        </div>
    );
};

NewNotification.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(NewNotification);
