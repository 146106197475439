import React, { Component } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import App from './app.component';
import Config from './config';
import { setLocale } from './store/actions/app.action';

import messages_en from './translations/en.json';
import messages_fr from './translations/fr.json';
import messages_nl from './translations/nl.json';
import messages_de from './translations/de.json';
import messages_ca from './translations/ca.json';

import * as en from 'react-intl/locale-data/en';
import * as fr from 'react-intl/locale-data/fr';
import * as nl from 'react-intl/locale-data/nl';
import * as de from 'react-intl/locale-data/de';
import * as ca from 'react-intl/locale-data/ca';
addLocaleData([...en, ...fr, ...nl, ...de, ...ca]);

const messages = {
    en: messages_en,
    fr: messages_fr,
    nl: messages_nl,
    de: messages_de,
    ca: messages_ca,
};

class ConnectedIntl extends Component {
    componentDidMount() {
        const { user } = this.props.store;

        let href = window.location.href;
        href = href.replace(/\/#/, '');
        const url = new URL(href);
        const searchParams = new URLSearchParams(url.search);
        const lang = searchParams.get('lang');

        let locale = Config.app.DEFAULT_LOCALE;
        if (lang) {
            locale = lang;
        } else if (user.account.locale) {
            locale = user.account.locale;
        } else {
            const navigatorLocale = navigator.language || navigator.userLanguage;
            locale = navigatorLocale.substring(0, 2).toLowerCase();
        }

        if (locale in messages) {
            this.props.setLocale(locale);
        } else {
            this.props.setLocale(Config.app.DEFAULT_LOCALE);
        }
    }

    render() {
        const {
            store: { app },
        } = this.props;
        return (
            <IntlProvider locale={app.locale} messages={messages[app.locale]}>
                <App />
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({ app, user }) => {
    return { store: { app, user } };
};

const mapDispatchToProps = {
    setLocale,
};

const Intl = connect(mapStateToProps, mapDispatchToProps)(ConnectedIntl);

export default Intl;
