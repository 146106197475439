import React, { Component } from 'react';
import Radium from 'radium';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { Growl } from 'primereact/growl';
import { PrivateRoute } from './app-private.component';
import { historyHelper as history } from './core/helpers/history.helper';
import { setTemplate } from './store/actions/app.action';
import { resetMessage } from './store/actions/message.action';
import Config from './config';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-circular-progressbar/dist/styles.css';

import './app.component.css';

import AccountPage from './user/account-page.component';
import AuthPage from './auth/auth-page.component';
import LoginPage from './login/login-page.component';
import DashboardPage from './dashboard/dashboard-page.component';
import NotificationsPage from './notification/notifications-page.component';
import OffersPage from './offer/offers-page.component';
import OfferConsultPage from './offer/offer-consult-page.component';
import ReferralsPage from './referral/referrals-page.component';
// import ReferralConsultPage from './referral/referral-consult-page.component';
import ReferralNewPage from './referral/referral-new-page.component';
import ShopPage from './shop/shop-page.component';

const routes = [
    { path: '/login', component: LoginPage },
    { path: '/auth', component: AuthPage, exact: true },
    { path: '/', component: DashboardPage, private: true, exact: true },
    { path: '/account', component: AccountPage, private: true },
    { path: '/dashboard', component: DashboardPage, private: true, exact: true },
    { path: '/notifications', component: NotificationsPage, private: true, exact: true },
    { path: '/offers', component: OffersPage, private: true, exact: true },
    { path: '/offers/consult', component: OfferConsultPage, private: true },
    { path: '/referrals', component: ReferralsPage, private: true, exact: true },
    // { path: '/referrals/consult', component: ReferralConsultPage, private: true },
    { path: '/referrals/new', component: ReferralNewPage, private: true },
    { path: '/shop', component: ShopPage, private: true },
];

class ConnectedApp extends Component {
    constructor(props) {
        super(props);
        let path = window.location.href;
        path = path.replace(window.location.origin, '');

        if (
            (!props.user.account.id || process.env.REACT_APP_KEY !== props.app.key) &&
            !path.includes('/auth')
        ) {
            history.push('/login');
        }

        let mystyle = document.getElementById('main-style');

        const my = mystyle.getAttribute('href');
        if (process.env.REACT_APP_STYLE_DEFAULT !== 'hunterz') {
            if (my !== `templates/${process.env.REACT_APP_STYLE_DEFAULT}/css/main.css`) {
                mystyle.setAttribute(
                    'href',
                    `templates/${process.env.REACT_APP_STYLE_DEFAULT}/css/main.css`
                );

                props.setTemplate(process.env.REACT_APP_STYLE_DEFAULT);
            }
        }
    }

    componentWillUpdate() {
        const { user } = this.props;

        let customstyle = document.getElementById('custom-style');
        const customUrl = customstyle.getAttribute('href');

        if (user.account.assets && Config.template.LIST.includes(user.account.assets)) {
            if (!customUrl.includes(`templates/${user.account.assets}`)) {
                const cssUrl = `templates/${user.account.assets}/css/custom.css?ver=${Date.now()}`;
                customstyle.setAttribute('href', cssUrl);
            }
        } else {
            if (!customUrl.includes(`templates/${process.env.REACT_APP_STYLE_DEFAULT}`)) {
                const cssUrl = `templates/${
                    process.env.REACT_APP_STYLE_DEFAULT
                }/css/custom.css?ver=${Date.now()}`;
                customstyle.setAttribute('href', cssUrl);
            }
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showMessages.messages) {
            const messages = nextProps.showMessages.messages;
            if (messages.length > 0) {
                const showMessages = messages.map(item => {
                    const summary = this.props.intl.formatMessage({ id: item.summary });
                    // Translate all details
                    let details = [];
                    if (item.detail && item.detail.length > 0) {
                        details = item.detail.map(detailItem => {
                            const detail = this.props.intl.formatMessage({ id: detailItem });
                            return <div>{detail}</div>;
                        });
                    }

                    return { ...item, summary: summary, detail: details };
                });
                this.growl.show(showMessages);
                this.props.resetMessage();
            }
        }
    }

    render() {
        return (
            <Router history={history}>
                <div className='o-app'>
                    <Growl
                        ref={el => {
                            this.growl = el;
                        }}
                        baseZIndex={1000}
                    ></Growl>
                    {routes.map((route, index) =>
                        route.private ? (
                            <PrivateRoute key={index} {...route} />
                        ) : (
                            <Route key={index} {...route} />
                        )
                    )}
                    <div className='clear'></div>
                </div>
            </Router>
        );
    }
}

ConnectedApp = Radium(ConnectedApp);

const mapStateToProps = ({ app, showMessages, user }) => {
    return { app, showMessages, user };
};

const mapDispatchToProps = {
    resetMessage,
    setTemplate,
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

App.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(App);
