import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './components/header.component';
import SmartMenu from './components/smartmenu.component';
import SmartNotif from './components/smartnotif.component';
import { setSmartMenu } from '../store/actions/app.action';
import { setList } from '../store/actions/notification.action';
import { fetchGet, setMenu, setRefresh } from '../store/actions/user.action';
import Config from '../config';
import './portal-page.component.css';

class ConnectedPortalPage extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidMount() {
        const {
            store: { app, user },
        } = this.props;

        if (app.smartMenu) {
            this.props.setSmartMenu(!app.smartMenu);
        }

        this.props.fetchGet();
        if (!user.lastRefresh || user.lastRefresh + Config.user.REFRESH < Date.now()) {
            const refreshId = setInterval(this.props.fetchGet, Config.user.REFRESH * 1000);
            this.props.setRefresh(refreshId);
        }
    }

    handleOnClick() {
        const {
            store: { notification, user },
        } = this.props;

        if (notification.list) {
            this.props.setList(false);
        }

        if (user.menu) {
            this.props.setMenu(false);
        }
    }

    render() {
        const {
            store: { app },
        } = this.props;

        return (
            <div className='o-portal' onClick={() => this.handleOnClick()}>
                <div className={`o-portal-smartmenu ${app.smartMenu ? 'active' : ''}`}>
                    <SmartMenu />
                </div>
                <div className={`o-portal-page ${app.smartMenu ? 'active' : ''}`}>
                    <div className='o-portal-header'>
                        <Header />
                    </div>
                    <div className='o-portal-content'>{this.props.children}</div>
                </div>
                <div className={`o-portal-smartnotif ${app.smartNotif ? 'active' : ''}`}>
                    <SmartNotif />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, notification, user }) => {
    return { store: { app, notification, user } };
};

const mapDispatchToProps = {
    fetchGet,
    setList,
    setMenu,
    setSmartMenu,
    setRefresh,
};

const PortalPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedPortalPage);

export default PortalPage;
