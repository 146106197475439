import React from 'react';
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import LoginEmail from './login-email.component';
import LoginPassword from './login-password.component';
import LoginSubmit from './login-submit.component';

let LoginForm = ({
    dirty,
    intl: { formatMessage },
    onChange,
    onKeyPress,
    onSubmit,
    submitted,
    value,
}) => (
    <div>
        <LoginEmail
            label={formatMessage({ id: 'app.login.username' })}
            name='username'
            value={value.username}
            onChange={onChange}
        />
        {dirty && !value.username && (
            <div className='o-error'>
                {formatMessage({ id: 'app.error.login.username.mandatory' })}
            </div>
        )}
        <LoginPassword
            label={formatMessage({ id: 'app.password' })}
            name='password'
            onChange={onChange}
            onKeyPress={onKeyPress ? onKeyPress : ''}
            value={value.password}
        />
        {dirty && !value.password && (
            <div className='o-error'>
                {formatMessage({ id: 'app.error.login.password.mandatory' })}
            </div>
        )}
        <LoginSubmit
            label={formatMessage({ id: 'app.login' })}
            disabled={submitted}
            onClick={onSubmit}
        />
    </div>
);

LoginForm = Radium(LoginForm);

LoginForm.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LoginForm);
