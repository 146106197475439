import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Dialog from '../shared/components/dialog.component';
import { Link } from 'react-router-dom';
import {
    fetchLogin,
    fetchPostRecovery,
    initGDPR,
    logout,
    fetchPostGDPR,
} from '../store/actions/user.action';
import ButtonRounded from '../shared/components/form/button-rounded.component';

import AuthForm from './components/auth-form.component';
import ForgotForm from './components/forgot-form.component';
import LoginForm from './components/login-form.component';

class ConnectedLoginPage extends Component {
    constructor(props) {
        super(props);

        // reset login status
        // this.props.logout();

        this.state = {
            auth: false,
            dirty: false,
            forgot: false,
            login: {
                password: '',
                username: '',
            },
            submitted: false,
        };

        this.handleAuth = this.handleAuth.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleForgot = this.handleForgot.bind(this);
        this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
        this.handleRGPD = this.handleRGPD.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    }

    handleAuth(event) {
        event.preventDefault();
        const auth = this.state.auth;
        this.setState({ auth: !auth });
    }

    handleChange(event) {
        let login = this.state.login;
        const { name, value } = event.target;
        login[name] = value;
        this.setState({ login: login });
    }

    handleForgot(event) {
        event.preventDefault();
        const forgot = this.state.forgot;
        this.setState({ forgot: !forgot });
    }

    handleForgotSubmit(event) {
        event.preventDefault();
        this.setState({ dirty: true, submitted: true });
        const { username } = this.state.login;
        if (username) {
            this.props.fetchPostRecovery(username);
        }
        this.setState({ submitted: false });
    }

    handleRGPD(value) {
        if (!value) {
            this.props.initGDPR();
            this.props.logout();
        } else {
            this.props.fetchPostGDPR();
        }
    }

    handleLoginSubmit(event) {
        event.preventDefault();
        this.setState({ dirty: true, submitted: true });
        const { username, password } = this.state.login;
        if (username && password) {
            this.props.fetchLogin(username, password);
        }
        this.setState({ submitted: false });
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleLoginSubmit(event);
        }
    }

    render() {
        const { auth, dirty, forgot, login } = this.state;
        const { user } = this.props.store;

        return (
            <div className='o-login'>
                <div className='o-login-logo'></div>
                <div className='o-login-welcome'>
                    {this.props.intl.formatMessage({ id: 'app.login.welcome.to' })}
                </div>
                {!auth && !forgot && (
                    <div className='o-login-form'>
                        <LoginForm
                            value={login}
                            onChange={this.handleChange}
                            onSubmit={this.handleLoginSubmit}
                            onKeyPress={this.handleOnKeyPress}
                            dirty={dirty}
                        />
                        <div className='o-login-link'>
                            <Link to='/' onClick={this.handleForgot}>
                                {this.props.intl.formatMessage({ id: 'app.forgot.password' })}
                            </Link>
                        </div>
                        {process.env.REACT_APP_AUTH === 'Y' && (
                            <div className='o-login-link-auth'>
                                <button
                                    className='o-login-link-auth-button'
                                    onClick={this.handleAuth}
                                >
                                    {this.props.intl.formatMessage({ id: 'app.auth.link' })}
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {forgot && (
                    <div className='o-login-form o-login-form-forgot'>
                        <ForgotForm
                            value={login}
                            onChange={this.handleChange}
                            onSubmit={this.handleForgotSubmit}
                            dirty={dirty}
                        />
                        <div className='o-login-link'>
                            <Link to='/' onClick={this.handleForgot}>
                                {this.props.intl.formatMessage({ id: 'app.back.to.login' })}
                            </Link>
                        </div>
                    </div>
                )}
                {process.env.REACT_APP_AUTH === 'Y' && auth && (
                    <div className='o-login-form o-login-form-auth'>
                        <div style={{ cursor: 'pointer' }}>
                            <AuthForm />
                        </div>
                        <div className='o-login-link'>
                            <Link to='/' onClick={this.handleAuth}>
                                {this.props.intl.formatMessage({ id: 'app.back.to.login' })}
                            </Link>
                        </div>
                    </div>
                )}
                <div className='o-login-deco1'></div>
                <div className='o-login-deco2'></div>
                <div className='o-portal-gdpr'>
                    <Dialog visible={user.gdprDialog && !user.account.gdpr}>
                        <div className='o-gdpr'>
                            <div className='o-gdpr-message'>
                                <div className='o-gdpr-text-wrapper'>
                                    <div
                                        className='o-gdpr-text'
                                        dangerouslySetInnerHTML={{
                                            __html: user.gdprText ? user.gdprText : '',
                                        }}
                                    />
                                </div>
                                <div className='o-gdpr-button'>
                                    <ButtonRounded
                                        className='o-button-rounded-secondary'
                                        onClick={() => this.handleRGPD(false)}
                                        label={'app.i.dont.accept'}
                                    />
                                    <ButtonRounded
                                        onClick={() => this.handleRGPD(true)}
                                        label={'app.i.accept'}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}

ConnectedLoginPage = Radium(ConnectedLoginPage);

const mapStateToProps = ({ app, user, style }) => {
    return { store: { app, user, style } };
};

const mapDispatchToProps = {
    fetchLogin,
    fetchPostGDPR,
    fetchPostRecovery,
    initGDPR,
    logout,
};

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginPage);

LoginPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LoginPage);
