import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputText from '../shared/components/form/input-text.component';
import InputTextarea from '../shared/components/form/input-textarea.component';
import OfferSummary from './components/offer-summary.component';
import Page from '../shared/components/page.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { fetchPost } from '../store/actions/referral.action';
import Config from '../config';

class ConnectedReferralNewPage extends Component {
    constructor(props) {
        super(props);

        const {
            intl: { formatMessage },
            store: {
                referral: { details },
            },
        } = this.props;

        this.genders = Config.gender.LIST.map(item => {
            return {
                label: formatMessage({ id: item.label }),
                value: item.value,
            };
        });

        this.state = {
            referral: {
                email: '',
                firstname: '',
                gender: '',
                lastname: '',
                linkedin: '',
                message: formatMessage({ id: 'app.referral.message.placeholder' }),
                offerId: details.id,
                phone: '',
            },
            dirty: false,
            disable: true,
        };

        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleBack() {
        const {
            app: { lastPage },
        } = this.props.store;
        history.push(`/${lastPage}`);
    }

    handleChange(event) {
        let { referral } = this.state;
        const { name, value } = event.target;
        if (value) {
            referral[name] = value;
        } else {
            delete referral[name];
        }
        this.setState({ referral, disable: false });
    }

    handleChangeGender(event) {
        let { referral } = this.state;
        const value = event.target.value;

        switch (value.length) {
            case 1:
                referral.gender = event.target.value[0];
                break;
            case 2:
                referral.gender = event.target.value[1];
                break;
            default:
                delete referral.gender;
        }

        this.setState({ referral, disable: false });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { referral } = this.state;

        const {
            store: { company },
        } = this.props;

        this.setState({ dirty: true });

        if (
            referral.email &&
            referral.firstname &&
            referral.gender &&
            referral.offerId &&
            referral.lastname &&
            (referral.linkedin || company.config.linkedinMandatory === 0) &&
            referral.phone
        ) {
            this.props.fetchPost(referral);
        }
    }

    render() {
        const { dirty, disable, referral } = this.state;

        const {
            intl: { formatMessage },
            store: {
                company,
                referral: { details, loading },
                user,
            },
        } = this.props;

        const linkedinWarning =
            dirty && company.config.linkedinMandatory && !referral.linkedin ? true : false;

        return (
            <Page className='o-referral-new' loading={loading}>
                <div className='o-content'>
                    <div className='o-referral-new-side o-content-aside'>
                        <OfferSummary
                            locale={user.account.locale}
                            offer={details}
                            onClick={() => this.handleBack()}
                            company={company}
                        />
                    </div>
                    <div className='o-referral-new-content o-content-main'>
                        <div className='o-referral-new-title'>
                            <span>{formatMessage({ id: 'app.your.candidat.profile' })}</span>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <InputMultiSelectInline
                                    label={formatMessage({
                                        id: 'app.gender',
                                    })}
                                    name='gender'
                                    onChange={this.handleChangeGender}
                                    options={this.genders}
                                    value={referral.gender ? [referral.gender] : null}
                                />
                                {dirty && !referral.gender && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.gender.mandatory' })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-4'>
                                <InputText
                                    label={formatMessage({
                                        id: 'app.lastname',
                                    })}
                                    name='lastname'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.lastname.placeholder',
                                    })}
                                    value={referral.lastname}
                                />
                                {dirty && !referral.lastname && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.lastname.mandatory' })}
                                    </div>
                                )}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <InputText
                                    label={formatMessage({
                                        id: 'app.firstname',
                                    })}
                                    name='firstname'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.firstname.placeholder',
                                    })}
                                    value={referral.firstname}
                                />
                                {dirty && !referral.firstname && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.firstname.mandatory' })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-4'>
                                <InputText
                                    label={formatMessage({
                                        id: 'app.email',
                                    })}
                                    name='email'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.email.placeholder',
                                    })}
                                    value={referral.email}
                                />
                                {dirty && !referral.email && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.email.mandatory' })}
                                    </div>
                                )}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <InputText
                                    label={formatMessage({
                                        id: 'app.phone',
                                    })}
                                    name='phone'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.phone.placeholder',
                                    })}
                                    value={referral.phone}
                                />
                                {dirty && !referral.phone && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.phone.mandatory' })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid o-grid-line'>
                            <div className='p-col-12 p-md-4'>
                                <InputText
                                    label={formatMessage({
                                        id: 'app.linkedin.profile',
                                    })}
                                    name='linkedin'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.linkedin.placeholder',
                                    })}
                                    value={referral.linkedin}
                                />
                                {linkedinWarning && (
                                    <div className='o-error'>
                                        {formatMessage({ id: 'app.referral.linkedin.mandatory' })}
                                    </div>
                                )}
                            </div>
                            <div className='p-col-12 p-md-4'></div>
                        </div>
                        <div className='o-referral-new-title'>
                            <span>{formatMessage({ id: 'app.referral.reason.title' })}</span>
                        </div>
                        <div className='p-grid o-grid-line'>
                            <div className='p-col-12 p-md-8'>
                                <InputTextarea
                                    label={formatMessage({
                                        id: 'app.referral.reason',
                                    })}
                                    name='reason'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.referral.reason.placeholder',
                                    })}
                                    value={referral.reason}
                                />
                            </div>
                        </div>
                        <div className='o-referral-new-title'>
                            <span>{formatMessage({ id: 'app.referral.message.title' })}</span>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-8'>
                                <InputTextarea
                                    label={formatMessage({
                                        id: 'app.referral.message',
                                    })}
                                    name='message'
                                    onChange={this.handleChange}
                                    placeholder={formatMessage({
                                        id: 'app.referral.message.placeholder',
                                    })}
                                    value={referral.message}
                                />
                            </div>
                        </div>
                        <div className='o-referral-new-submit'>
                            <SubmitButton
                                label={formatMessage({ id: 'app.referral' })}
                                onClick={this.handleSubmit}
                                disabled={disable || loading}
                            />
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, referral, user }) => {
    return { store: { app, company, referral, user } };
};

const mapDispatchToProps = {
    fetchPost,
};

const ReferralNewPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedReferralNewPage);

ReferralNewPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReferralNewPage);
