import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Gifts from '../../shared/components/gifts.component';
import SubmitButton from '../../shared/components/form/submit-button.component';
import moment from 'moment';
require('moment/min/locales.min');

let OfferDetails = ({ intl: { formatMessage }, locale = 'fr', offer, onClick, company }) => {
    moment.locale(locale);

    return (
        <div className='o-details'>
            <div className='o-details-item' key={offer.id}>
                <div className='o-details-logo'></div>
                <div className='o-details-header'>
                    <div className='o-details-title'>{offer.title}</div>
                    <div className='o-details-location'>
                        {`${offer.location} - ${moment(offer.createdAt).fromNow()}`}
                    </div>
                    <div className='o-details-title-desc'>{offer.titleDescription}</div>
                </div>
                <div className='o-details-gifts'>
                    <Gifts
                        currency={offer.currency}
                        points={offer.points}
                        primes={offer.primes}
                        company={company}
                    />
                </div>
                <div className='o-details-post'>
                    <div className='o-details-post-label'>
                        {formatMessage({ id: 'app.offer.description' })}
                    </div>
                    <div
                        className='o-offers-post-text'
                        dangerouslySetInnerHTML={{ __html: offer.description }}
                    ></div>
                </div>
                <div className='o-details-submit'>
                    <SubmitButton label={formatMessage({ id: 'app.referral' })} onClick={onClick} />
                </div>
            </div>
        </div>
    );
};

OfferDetails.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OfferDetails);
