import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import Page from '../shared/components/page.component';
import { menuItem, setPage } from '../store/actions/app.action';
import { fetchPostRead } from '../store/actions/notification.action';
import { setDetails as setOfferDetails } from '../store/actions/offer.action';
import { setDetails as setReferralDetails } from '../store/actions/referral.action';
import Config from '../config';
import moment from 'moment';
require('moment/min/locales.min');

class ConnectedNotificationsPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, notification) {
        const {
            store: {
                offer: { offers },
                referral: { referrals },
            },
        } = this.props;

        if (notification.has_read !== '1') {
            this.props.fetchPostRead(notification.id);
        }

        switch (action) {
            case Config.action.OFFERS:
                const offerData = offers.filter(item => item.id === notification.data.offer_id);
                if (!offerData) {
                    history.push(`/offers`);
                }

                this.props.setOfferDetails(offerData[0]);
                history.push(`/offers/consult`);
                break;
            case Config.action.REFERRALS:
                const referralData = referrals.filter(
                    item => item.id === notification.data.coopt_id
                );

                if (referralData) {
                    this.props.setReferralDetails(referralData[0]);
                }

                history.push(`/referrals`);
                break;
            case Config.action.SHOP:
                history.push(`/shop`);
                break;
            default:
        }
    }

    render() {
        const {
            intl: { formatMessage },
            store: {
                app,
                notification: { loading, notifications },
            },
        } = this.props;

        const dynamicNotificationsList = notifications.map(notification => {
            var date = new Date(notification.time * 1000);

            let icon = '';
            let click = null;
            switch (notification.type) {
                case Config.notification.type.COOPT:
                    icon = notification.hasRead
                        ? 'notification-coopt.png'
                        : 'notification-coopt-new.png';
                    click = () => this.handleAction(menuItem.REFERRALS, notification);
                    break;
                case Config.notification.type.OFFER:
                    icon = notification.hasRead
                        ? 'notification-offer.png'
                        : 'notification-offer-new.png';
                    click = () => this.handleAction(menuItem.OFFERS, notification);
                    break;
                case Config.notification.type.SHOP:
                    icon = notification.hasRead
                        ? 'notification-shop.png'
                        : 'notification-shop-new.png';
                    click = () => this.handleAction(menuItem.SHOP, notification);
                    break;
                default:
                    icon = notification.hasRead
                        ? 'notification-general.png'
                        : 'notification-general-new.png';
            }

            const style = {
                backgroundImage: `url('/templates/${app.template}/media/${icon}')`,
            };

            return (
                <div
                    className='o-notifications-item'
                    key={notification.id}
                    onClick={click ? click : null}
                >
                    <div className='o-notifications-icon' style={style}></div>
                    <div className='o-notifications-text'>{notification.text}</div>
                    <div className='o-notifications-time'>{moment(date).fromNow()}</div>
                </div>
            );
        });

        return (
            <Page className='o-notifications' loading={loading}>
                <div className='o-notifications-title'>
                    {formatMessage({
                        id: 'app.notifications',
                    })}
                </div>
                <div className='o-notifications-description'>
                    {formatMessage({
                        id: 'app.notifications.description',
                    })}
                </div>
                <div className='o-notifications-items'>{dynamicNotificationsList}</div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, notification, offer, referral }) => {
    return { store: { app, notification, offer, referral } };
};

const mapDispatchToProps = {
    fetchPostRead,
    setOfferDetails,
    setPage,
    setReferralDetails,
};

const NotificationsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedNotificationsPage);

NotificationsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(NotificationsPage);
