import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './prototypes.js';

import Intl from './intl.component';
import configureStore from './configure-store';

const { persistor, store } = configureStore();

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Intl />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
