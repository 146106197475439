import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PortalPage from './portal/portal-page.component';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('token') ? (
                <PortalPage>
                    <Component {...props} />
                </PortalPage>
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
);
