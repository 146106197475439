import { postService as post } from '../../shared/services/post.service';
import { logout } from './user.action';

export const type = {
    OFFER_FETCH_ERROR: 'OFFER_FETCH_ERROR',
    OFFER_FETCH_PENDING: 'OFFER_FETCH_PENDING',
    OFFER_FETCH_GET_SUCCESS: 'OFFER_FETCH_GET_SUCCESS',
    OFFER_SET_DETAILS: 'OFFER_SET_DETAILS',
};

export const initialState = {
    loading: false,
    error: {},
    contracts: [],
    departments: [],
    details: {},
    locations: [],
    offers: [],
    seniorities: [],
};

export const fetchError = error => {
    return { type: type.OFFER_FETCH_ERROR, error };
};

export const fetchPending = () => {
    return { type: type.OFFER_FETCH_PENDING };
};

export const fetchGetSuccess = data => {
    const contracts = [];
    const departments = [];
    const locations = [];
    const seniorities = [];

    let offers = [];
    if (data) {
        if (typeof data.offers !== undefined && Array.isArray(data.offers)) {
            data.offers.forEach(offer => {
                if (
                    offer.contract &&
                    offer.contract.trim() &&
                    !contracts.includes(offer.contract)
                ) {
                    contracts.push(offer.contract);
                }

                if (
                    offer.department &&
                    offer.department.trim() &&
                    !departments.includes(offer.department)
                ) {
                    departments.push(offer.department);
                }

                if (
                    offer.location &&
                    offer.location.trim() &&
                    !locations.includes(offer.location)
                ) {
                    locations.push(offer.location);
                }

                if (
                    offer.seniority &&
                    offer.seniority.trim() &&
                    !seniorities.includes(offer.seniority)
                ) {
                    seniorities.push(offer.seniority);
                }
            });

            offers = data.offers;
        }
    }

    contracts.sort();
    departments.sort();
    locations.sort();
    seniorities.sort();

    return {
        type: type.OFFER_FETCH_GET_SUCCESS,
        contracts,
        departments,
        locations,
        offers,
        seniorities,
    };
};

export const fetchGet = () => {
    return dispatch => {
        dispatch(fetchPending());

        post.getOffers().then(
            response => {
                dispatch(fetchGetSuccess(response.data));
            },
            error => {
                dispatch(logout(error));
            }
        );
    };
};

export const setDetails = details => {
    return { type: type.OFFER_SET_DETAILS, details };
};
