import React from 'react';
import Radium from 'radium';
import { SelectButton } from 'primereact/selectbutton';

let InputMultiSelectInline = props => {
    return (
        <div className='o-input-multiselect-inline'>
            {props.label && <label>{props.label}</label>}
            <SelectButton
                name={props.name}
                multiple={props.multiple ? props.multiple : true}
                value={props.value}
                options={props.options}
                onChange={props.onChange.bind(this)}
            />
        </div>
    );
};

InputMultiSelectInline = Radium(InputMultiSelectInline);

export default InputMultiSelectInline;
