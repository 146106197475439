import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Dialog from './dialog.component';
import ButtonRounded from './form/button-rounded.component';

const Page = ({ children, className, congrat, intl: { formatMessage }, loading, onOk }) => {
    return (
        <div className={`o-page ${className}`}>
            {loading && (
                <div className='o-page-loader'>
                    <i className='pi pi-spin pi-spinner'></i>
                </div>
            )}
            <div className='o-page-children'>{children}</div>
            <Dialog visible={congrat ? true : false}>
                <div className='o-congrat'>
                    <div className='o-congrat-picture'></div>
                    <div className='o-congrat-message'>
                        <div className='o-congrat-title'>
                            {formatMessage({ id: 'app.dialog.congrat.title' })}
                        </div>
                        <div className='o-congrat-subtitle'>
                            {formatMessage({ id: 'app.dialog.congrat.subtitle' })}
                            {congrat}
                        </div>
                        <div className='o-congrat-text'>
                            {formatMessage({ id: 'app.dialog.congrat.text' })}
                        </div>
                        {onOk && <ButtonRounded onClick={onOk} label={'app.finish'} />}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

Page.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Page);
