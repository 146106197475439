import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { tools } from '../../shared/services/tools.service';

const GiftsDashboard = ({ company, intl: { formatMessage }, user }) => {
    return (
        <div className='o-gifts-dashboard'>
            <div className='o-gifts-dashboard-title'>
                {formatMessage({ id: 'app.gifts.dashboard.title' })}
            </div>
            <div className='o-gifts-dashboard-description'>
                {formatMessage({ id: 'app.gifts.dashboard.description' })}
            </div>
            <div className='o-gifts-dashboard-items'>
                <div className='o-gifts-dashboard-item'>
                    <div className='o-gifts-dashboard-label'>
                        {formatMessage({ id: 'app.points.won' })}
                    </div>
                    <div className='o-gifts-dashboard-value'>{user.points.available}</div>
                </div>
                {company.config && parseInt(company.config.doublemoney) === 1 && (
                    <div className='o-gifts-dashboard-item'>
                        <div className='o-gifts-dashboard-label'>
                            {formatMessage({ id: 'app.bonus.won' })}
                        </div>
                        <div className='o-gifts-dashboard-value'>
                            {user.referrals.bonusReceived}
                            <span>{tools.currencySymbol(company.currency)}</span>
                        </div>
                    </div>
                )}
                {company.config && parseInt(company.config.doublemoney) === 1 && (
                    <div className='o-gifts-dashboard-item'>
                        <div className='o-gifts-dashboard-label'>
                            {formatMessage({ id: 'app.bonus.available' })}
                        </div>
                        <div className='o-gifts-dashboard-value'>
                            {user.referrals.bonusAvailable}
                            <span>{tools.currencySymbol(company.currency)}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

GiftsDashboard.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(GiftsDashboard);
