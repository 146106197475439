import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import InputSelect from '../../shared/components/form/input-select.component';
import InputText from '../../shared/components/form/input-text.component';

const ReferralsFilter = ({
    intl: { formatMessage },
    statuses,
    steps,
    onChange,
    stepValue,
    statusValue,
}) => {
    const [disableSteps, setDisableSteps] = useState(false);

    useEffect(() => {
        switch (statusValue) {
            case '1':
            case '-1':
                setDisableSteps(true);
                break;
            default:
                setDisableSteps(false);
        }
    }, [statusValue]);

    return (
        <div className='o-referrals-filter'>
            <div className='o-referrals-filter-title'>
                {formatMessage({ id: 'app.referrals.filter.title' })}
            </div>
            <div className='o-referrals-filter-status'>
                <InputSelect
                    label={formatMessage({ id: 'app.status' })}
                    name='status'
                    none={true}
                    options={statuses}
                    onChange={onChange}
                    value={statusValue}
                />
            </div>
            <div className='o-referrals-filter-step'>
                <InputSelect
                    label={formatMessage({ id: 'app.step' })}
                    name='step'
                    none={true}
                    options={steps}
                    onChange={onChange}
                    value={stepValue}
                    disabled={disableSteps}
                />
            </div>
            <div className='o-referrals-filter-finder'>
                <i className='fas fa-search' />
                <InputText
                    name='find'
                    onChange={onChange}
                    placeholder={formatMessage({ id: 'app.find' })}
                />
            </div>
        </div>
    );
};

ReferralsFilter.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReferralsFilter);
