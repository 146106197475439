import React, { Component } from 'react';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { injectIntl, intlShape } from 'react-intl';
import OfferDetails from './components/offer-details.component';
import Page from '../shared/components/page.component';
import { setDetails } from '../store/actions/referral.action';
import Config from '../config';

class ConnectedOfferConsultPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleAction(action) {
        switch (action) {
            case Config.action.OFFERS:
                history.push(`/offers`);
                break;
            default:
        }
    }

    handleClick() {
        this.props.setDetails(this.props.store.offer.details);
        history.push(`/referrals/new`);
    }

    render() {
        const {
            store: {
                company,
                offer: { details, loading },
                user,
            },
        } = this.props;

        return (
            <Page className='o-offer-consult' loading={loading}>
                <div className='o-content'>
                    <div className='o-offer-consult-content o-content-main'>
                        <OfferDetails
                            locale={user.account.locale}
                            offer={details}
                            onClick={() => this.handleClick()}
                            company={company}
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ company, offer, user }) => {
    return { store: { company, offer, user } };
};

const mapDispatchToProps = {
    setDetails,
};

const OfferConsultPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedOfferConsultPage);

OfferConsultPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OfferConsultPage);
