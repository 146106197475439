const Config = {
    action: {
        DETAILS: 'details',
        LIST: 'list',
        MENU: 'menu',
        NOTIFICATION: 'notification',
        OFFERS: 'offers',
        REFERRAL: 'referral',
        REFERRALS: 'referrals',
        SHOP: 'shop',
    },
    app: {
        DEFAULT_LOCALE: 'fr',
    },
    dashboard: {
        offer: {
            MAX: 5,
        },
        referral: {
            MAX: 5,
        },
    },
    gender: {
        LIST: [
            { label: 'app.gender.mr', value: 'm' },
            { label: 'app.gender.ms', value: 'w' },
        ],
    },
    http: {
        CODE_204: 'app.error.no.content',
        CODE_400: 'app.error.bad.request',
        CODE_405: 'app.error.method.not.allowed',
        CODE_422: 'app.error.unprocessable.entity',
        CODE_500: 'app.error.internal.server.error',
        CODE_503: 'app.error.service.unavailable',
    },
    media: {
        OFFER_PIC: 'offer-pic{1}.png',
        OFFER_PIC_MAX: 8,
        STEP_CHECK: 'step-check.png',
    },
    notification: {
        list: {
            MAX: 5,
        },
        type: {
            COOPT: 'coopt',
            OFFER: 'offer',
            SHOP: 'shop',
        },
    },
    referral: {
        status: {
            LIST: [
                { label: 'app.referral.status.progress', value: '0' },
                { label: 'app.referral.status.hired', value: '1' },
                { label: 'app.referral.status.stop', value: '-1' },
            ],
        },
    },
    showMessage: {
        LIFE: 10000,
    },
    socialShare: {
        FACEBOOK: 'facebook',
        LINKEDIN: 'linkedin',
    },
    template: {
        LIST: ['aema', 'arkea', 'beerefer', 'fdj', 'gfi', 'hunterz', 'talentsoft'],
    },
    user: {
        REFRESH: 60 * 5,
    },
};

export default Config;
