import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { tools } from '../../shared/services/tools.service';
import './dialog.component.css';

let Gifts = ({ currency, collected, intl: { formatMessage }, points, primes, company }) => {
    const isCollected = !collected && collected !== 0 ? false : true;
    return (
        <div className='o-gifts'>
            {isCollected && (
                <div className='o-gifts-item'>
                    <div className='o-gifts-label'>{formatMessage({ id: 'app.collected' })}</div>
                    <div className='o-gifts-points'>
                        {collected}
                        <span>/{points}</span>
                    </div>
                </div>
            )}
            {!isCollected && (
                <div className='o-gifts-item'>
                    <div className='o-gifts-label'>{formatMessage({ id: 'app.to.collect' })}</div>
                    <div className='o-gifts-points'>{points}</div>
                </div>
            )}
            {company.config && parseInt(company.config.doublemoney) === 1 && (
                <div className='o-gifts-item o-gifts-item-primes'>
                    <div className='o-gifts-label'>{formatMessage({ id: 'app.to.win' })}</div>
                    <div className='o-gifts-primes'>
                        {primes}
                        <span>{tools.currencySymbol(currency)}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

Gifts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Gifts);
