import React from 'react';
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import { Dialog as DialogBox } from 'primereact/dialog';
import ButtonRounded from './form/button-rounded.component';
import './dialog.component.css';

let Dialog = ({ children, onCancel, onHide, onOk, onSubmit, text, visible }) => {
    const styleDialog = {
        backgroundColor: '#f2f2f2',
        textAlign: 'center',
        padding: '2em 5em 1em 5em',
        width: '40em !important',
    };

    const onHideMandatory = () => {
        return true;
    };

    return (
        <DialogBox
            showHeader={false}
            visible={visible}
            modal={true}
            style={styleDialog}
            onHide={onHide ? onHide : onHideMandatory}
        >
            <div className='o-dialog'>
                {text && <div className='o-dialog-text'>{text}</div>}
                {children && <div className='o-dialog-children'>{children}</div>}
                <div className='o-dialog-buttons'>
                    {onCancel && <ButtonRounded onClick={onCancel} label={'app.cancel'} />}
                    {onOk && <ButtonRounded onClick={onOk} label={'app.ok'} />}
                    {onSubmit && <ButtonRounded onClick={onSubmit} label={'app.confirm'} />}
                </div>
            </div>
        </DialogBox>
    );
};

Dialog = Radium(Dialog);

Dialog.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Dialog);
