import React from 'react';
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import './button-rounded.component.css';

let ButtonRounded = ({ className, disabled, intl: { formatMessage }, label, onClick }) => {
    return (
        <button
            type='button'
            className={`o-button-rounded ${className}`}
            onClick={onClick.bind(this)}
            disabled={disabled ? disabled : false}
        >
            {formatMessage({ id: label ? label : 'app.save' })}
        </button>
    );
};

ButtonRounded = Radium(ButtonRounded);

ButtonRounded.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ButtonRounded);
