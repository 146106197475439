import { httpService as http } from '../../core/services/http.service';

export const type = {
    APP_INIT: 'APP_INIT',
    APP_INIT_PAGE: 'APP_INIT_PAGE',
    APP_SET_KEY: 'APP_SET_KEY',
    APP_SET_LOCALE: 'APP_SET_LOCALE',
    APP_SET_PAGE: 'APP_SET_PAGE',
    APP_SET_SIDE_MENU: 'APP_SET_SIDE_MENU',
    APP_SET_SMART_MENU: 'APP_SET_SMART_MENU',
    APP_SET_SMART_NOTIF: 'APP_SET_SMART_NOTIF',
    APP_SET_TEMPLATE: 'APP_SET_TEMPLATE',
};

export const menuItem = {
    ACCOUNT: 'account',
    DASHBOARD: 'dashboard',
    LIST: 'list',
    LOGOUT: 'logout',
    MENU: 'menu',
    NOTIFICATIONS: 'notifications',
    OFFER: 'offer',
    OFFERS: 'offers',
    REFERRALS: 'referrals',
    SHOP: 'shop',
};

export const initialState = {
    key: '',
    lastPage: '',
    locale: 'fr',
    page: menuItem.DASHBOARD,
    smartMenu: false,
    smartNotif: false,
    sideMenu: menuItem.OFFERS,
    template: 'hunterz',
};

export const initApp = () => {
    return { type: type.APP_INIT };
};

export const initPage = page => {
    return { type: type.APP_INIT_PAGE, page: page };
};

export const fetchPostPicture = pictureFile => {
    let data = new FormData();
    data.append('picture', pictureFile);

    return http.postFile(`/media`, data).then(
        response => {
            return response.data.picture;
        },
        error => {
            console.log(error);
            return null;
        }
    );
};

export const fetchPutPicture = (oldPicture, pictureFile) => {
    let data = new FormData();
    data.append('picture', pictureFile);

    return http.putFile(`/media/${oldPicture}`, data).then(
        response => {
            return response.data.picture;
        },
        error => {
            console.log(error);
            return null;
        }
    );
};

export const setKey = key => {
    return { type: type.APP_SET_KEY, key };
};

export const setLocale = locale => {
    return { type: type.APP_SET_LOCALE, locale };
};

export const setPage = page => {
    return { type: type.APP_SET_PAGE, page };
};

export const setSideMenu = sideMenu => {
    return { type: type.APP_SET_SIDE_MENU, sideMenu };
};

export const setSmartMenu = smartMenu => {
    return { type: type.APP_SET_SMART_MENU, smartMenu };
};

export const setSmartNotif = smartNotif => {
    return { type: type.APP_SET_SMART_NOTIF, smartNotif };
};

export const setTemplate = template => {
    return { type: type.APP_SET_TEMPLATE, template };
};
