import { initialState, type } from '../actions/notification.action';

const notification = (state = initialState, action) => {
    switch (action.type) {
        case type.NOTIFICATION_FETCH_PENDING:
            return { ...state, loading: true };
        case type.NOTIFICATION_FETCH_ERROR:
            return { ...state, loading: false, error: action.error };
        case type.NOTIFICATION_FETCH_POST_READ_SUCCESS:
            return { ...state, loading: false };
        case type.NOTIFICATION_SET_LIST:
            return { ...state, list: action.list };
        case type.NOTIFICATION_SET_NOTIFICATIONS:
            return { ...state, notifications: action.notifications };
        default:
            return state;
    }
};

export default notification;
